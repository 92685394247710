const {isGTMEnabled} = require('../components/helpers');

function getNumberSwatchDisplay($swatch) {
	var numberSwatchDisplay = 6;
	var widthHeight = $(window).height();
    var widthWindow = $(window).width();
	if($swatch.closest('.addon-item').length) {
		numberSwatchDisplay = 3;
	}
	const breakpoint = window.matchMedia('(max-width:767px)');

	if (!breakpoint.matches) {
		numberSwatchDisplay = 6;
		if($swatch.closest('.addon-item').length) {
			numberSwatchDisplay = 3;
		}

		if ( widthWindow >= 1024 && widthWindow <= 1366 && widthWindow < widthHeight) {
			numberSwatchDisplay = 3;
		} 

		if($swatch.closest('.addon-item').length && widthWindow <= 1366) {
			if ( widthWindow > 991) {
				numberSwatchDisplay = 1;
			} else {
				numberSwatchDisplay = 2;
			}
		}

	} else {
		numberSwatchDisplay = 4;
		if($swatch.closest('.addon-item').length) {
			numberSwatchDisplay = 2;
		}
	}
	return numberSwatchDisplay;
}

function swatchDisplay() {
	var itemDisplay = getNumberSwatchDisplay($('.list-swatches .color-attribute'));
	$('.list-swatches .color-attribute').hide();
	var numSwatches = $('.list-swatches .color-attribute').length;

	if (numSwatches == itemDisplay + 2) {
		$('.list-swatches .color-attribute:lt(' + itemDisplay + 1 + ')').show();
		$('.list-swatches .color-attribute-more').hide();
	} else {
		$('.list-swatches .color-attribute:lt(' + itemDisplay + ')').show();

		if (numSwatches > itemDisplay + 1) {
			$('.list-swatches .color-attribute-more').show();
			$('.list-swatches .number-view-more').html(numSwatches - itemDisplay - 1);
		} else {
			$('.list-swatches .color-attribute-more').hide();
		}
	}
	
	$('.list-swatches .color-attribute-more').on('click', function(e) {
		$(this).parent().find('.color-attribute').show();
		$(this).hide();
	});
}

module.exports = {
	swatch({
		productSelector = '.product-detail',
		productDetails = '.pdp-sidebar',
		productPriceSelector = '[data-gtm-price]',
		productTileNameSelector = '.product-name',
		productTileDescriptionSelector = '.product-description',
		colorAttributesContainer = '.color-attributes-container',
	} = {}) {
		if (!isGTMEnabled()) {
			return;
		}

		$('.color-attribute').on('click', function(e) {
			e.preventDefault();

			const $productDetails = $(this).closest(productDetails);
			const gtmData = $productDetails.find(colorAttributesContainer).data('gtm');

			$('body').bootstrap('gtm', {
				type: 'swatch',
				data: gtmData,
			});
		});
	},
	swatchDisplay: swatchDisplay,
};

$(window).on("orientationchange", function () {
    setTimeout(function() {
        swatchDisplay();
    }, 150);
});