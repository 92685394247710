function formatPrice(price) {
	return price.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

function calculateTotal($productContainer) {
	let total = parseFloat($productContainer.find('.mattress-total-price').find('.value').attr('content'));

	$('.addon-checkbox').each(function() {
		if ($(this).is(':checked')) {
			let price = parseFloat($(this).closest('.addon-item').find('.value').attr('content'));
			total += price;
		}
	});
	$('.mattress-total-price').find('.value').text(formatPrice(total));
}

function loadCustomSelect() {
    if ($('.custom-select-menu').length) {
		var optionMenu = $('.custom-select-menu');

		var topBody = 0;
		var isScrollBody = false;

		$('.custom-select-btn').on('click', function() {
			if($(this).parent().hasClass('active')) {
				$(this).parent().removeClass('active');
			} else {
				optionMenu.removeClass('active');
				$(this).parent().addClass('active');

				const breakpoint = window.matchMedia('(max-width:991px)');
				if (breakpoint.matches) {
					setTimeout(function () {
						marginTopMain = parseFloat($('#maincontent').css('margin-top').replace('px', ''));
						if (!topBody) {
							topBody = $(document).scrollTop();
						}
						$('body').addClass('modal-open');
						$('body').addClass('disable-overflow');
						$('body > .modal-backdrop:not(".modal-backdrop-custom-select")').hide();
						$('body > .modal-backdrop-custom-select').remove();
						$('body').append('<div class="modal-backdrop show modal-backdrop-custom-select"></div>');
						$('.mattress-quick-view-dialog').addClass('action');
						if(!isScrollBody) {
							$('body').css('top', - (topBody));
							isScrollBody = true;
						}
					}, 200);
				}
			}
		});

		$('.custom-options').find('li').on('click', function() {
			if ($(this).closest('.product-quickview').length === 0) {
				$(this).parent().find('li').removeClass('selected');
				$(this).addClass('selected');
			}

			let selectedOption = $(this).html();
			$(this).closest('.custom-select-menu').find('.results-text-option').html(selectedOption);

			$(this).closest('.custom-select-menu').removeClass('active');
			const breakpoint = window.matchMedia('(max-width:991px)');
			if (breakpoint.matches && isScrollBody) {
				$('body').removeClass('disable-overflow');
				$('body').removeClass('modal-open');
				$('body > .modal-backdrop-custom-select').remove();
				$('body > .modal-backdrop:not(".modal-backdrop-custom-select")').show();
				$(document).scrollTop(topBody);
				topBody = 0;
				isScrollBody = false;
			}
		});

		$('body').on('click', function (event) {
			if (!$(event.target).closest('.custom-select-menu').length) {
				optionMenu.removeClass('active');
				const breakpoint = window.matchMedia('(max-width:991px)');
				if (breakpoint.matches) {
					if($('.storeselect-flyout').position().left && !$(event.target).closest('.storeselect-toggle').length) {
						$('body').removeClass('disable-overflow');
						$('body').removeClass('modal-open');
					}
					$('body > .modal-backdrop:not(".modal-backdrop-custom-select")').show();
					$('body > .modal-backdrop-custom-select').remove();
					$('.mattress-quick-view-dialog').removeClass('action');
					if (isScrollBody) {
						$(document).scrollTop(topBody);
						topBody = 0;
						isScrollBody = false;
					}
				}
			}
		});
	}

    // Event listener for checkbox change
    $('.addon-checkbox').change(function() {
		var $productContainer = $(this).closest('.product-detail');
		if ($productContainer.find('.mattress-total-price').length > 0) {
			calculateTotal($productContainer);
		} else if ($(this).closest('#plpQuickViewModal').find('.mattress-total-price').length > 0) {
			calculateTotal($(this).closest('#plpQuickViewModal'));
		}

    });
}

module.exports.loadCustomSelect = loadCustomSelect;