'use strict';
var detail = require('base/product/detail');
var base = require('./base');
require('base/components/spinner');


detail.showSpinner = function () {
    $('body').on('product:beforeAddToCart product:beforeAttributeSelect', function () {
        $.spinner().start();
    });
};

detail.addToCart = base.addToCart;

module.exports = detail;
